import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Row, Text, Button, modal, TouchField, showPopupMessage } from 'components';
import { useWindowDimensions } from 'react-native';
import { TPrintJob } from 'type';
import { COLOR } from 'const';
import Store from 'store';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ScrollView } from 'react-native';
import { ValHelper } from 'helpers';

interface IProps {
    printJob: TPrintJob
}

const calculateDiscountPercent = (target, compare) => {
    return Math.floor((target - compare) * 100 / target) + '%';
}

let _lastCalled = 0;
const throttle = (func) => {
    return function () {
        const now = Date.now();
        if (now - _lastCalled > 2000) {
            func();
            _lastCalled = now;
        }
    }
}

const PaymentForm = ({ onPaid, amount, resellerId, queryString }) => {
    const formPaymentRef = useRef<HTMLFormElement>(null);
    const stripe = useStripe();
    const didPressPay = useRef(false);
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async e => {
        !!e && e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // return_url: process.env.NODE_ENV === 'production'
                //     ? `https://dev.bg-production.personify.tech/resellers/${resellerId}/deposit-funds/complete${queryString}`
                //     : `http://localhost:19006/resellers/${resellerId}/deposit-funds/complete${queryString}`,
                return_url: `${window.location.protocol}//${window.location.host}/resellers/${resellerId}/deposit-funds/complete${queryString}`,
            },
        });

        if (result.error) {
            console.log(result.error.message);
        } else {
            onPaid(result);
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        didPressPay.current = false;
    }
    return (
        <form ref={formPaymentRef} onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                mt1
                isLoading={isLoading}
                solid
                text={'Pay ' + ValHelper.formatMoney(amount)}
                onPress={throttle(() => {
                    if (didPressPay.current) return;
                    didPressPay.current = true;
                    setIsLoading(true);
                    handleSubmit(undefined);
                })}
                height={40}
                width='100%'
            />
            <Button
                mt1
                outline={true}
                height={40}
                width='100%'
                text={`Cancel Payment`}
                onPress={() => {
                    modal.hide();
                }}
            />
        </form>
    );
}

const DepositFundPopup = ({ resellerId, amount, currency }: any) => {
    const PaymentStore = Store.usePaymentStore();
    const { stripePromise } = PaymentStore;
    console.log('stripePromise', stripePromise);
    const { width, height } = useWindowDimensions();
    const [variations, setVariations] = useState([]);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [fulfillAmount, setFulfillAmount] = useState(amount);
    const { getExistedCard, card, balance, stripeClientSecret, removeCard, removingCard } = PaymentStore;
    const [isPaying, setIsPaying] = useState(false);
    console.log('stripeClientSecret', stripeClientSecret);
    const didPressPay = useRef(false);

    const [payData, setPayData] = useState<any>({
        show: false,
        data: {
            displayAmount: `${ValHelper.formatMoney(amount)} ${currency}`,
            amount: amount,
            currency: currency?.toLowerCase() || 'gbp',
        },
        options: {
            clientSecret: '',
        },
        savedPaymentMethods: [],
    });

    useEffect(() => {
        (async () => {
            const res = await Store.Api.Payment.stripeCreateDeposit({
                amount: amount,
                currency: currency,
            });
            if (res.data.success && res.data.data?.client_secret) {
                setPayData({
                    ...payData,
                    show: true,
                    options: {
                        clientSecret: res.data.data?.client_secret,
                    },
                });
                return;
            }
            if (res.data.success && res.data.extraConfirmStepData && res.data.extraConfirmStepData.paymentMethods) {
                setPayData({
                    ...payData,
                    show: true,
                    options: {
                        clientSecret: '',
                    },
                    savedPaymentMethods: res.data.extraConfirmStepData.paymentMethods,
                })
            }
        })();
    }, []);

    const renderPayment = () => {
        console.log('payData', payData);
        if (!payData.options.clientSecret && !!payData.savedPaymentMethods && payData.savedPaymentMethods.length > 0) {
            // val -> https://stripe.com/docs/api/payment_methods
            return (
                <Col flex1 middle>
                    <Text bold mb2>Amount: {ValHelper.formatMoney(payData.data?.displayAmount)}</Text>
                    {payData.savedPaymentMethods.map((val, i) => {
                        return (
                            <Button
                                solid={i === 0}
                                outline={i !== 0}
                                height={40}
                                width={320}
                                isLoading={isPaying}
                                text={`Pay with: ${val.card?.brand} ${val.card?.last4}`}
                                onPress={throttle(async () => {
                                    if (didPressPay.current) return;
                                    didPressPay.current = true;
                                    setIsPaying(true);
                                    const { data } = payData;
                                    const res = await Store.Api.Payment.stripeCreateDeposit({
                                        amount: amount,
                                        currency: currency,
                                        paymentMethodId: val.id,
                                    });
                                    if (!res.data.success || !!res.data.error) {
                                        showPopupMessage({
                                            title: '',
                                            content: String(res.data.error),
                                            buttonOkText: 'OK',
                                            // 
                                            typeHighlight: 'danger',
                                            contentHighlight: 'Error'
                                        });
                                        return;
                                    }
                                    console.log('STRIPE CREATE DEPOSIT', JSON.stringify(res.data.data));
                                    const payment_intent_id = res.data.data.id;
                                    const queryString = `?amount=${payData.data?.amount}&f_amount=${fulfillAmount}&currency=${currency}&payment_intent=${payment_intent_id}`;
                                    const return_url = `${window.location.protocol}//${window.location.host}/resellers/${resellerId}/deposit-funds/complete${queryString}`;
                                    console.log('return_url', return_url);
                                    // alert(JSON.stringify(res.data.data));
                                    window.location.href = return_url;
                                    setIsPaying(false);
                                })}
                                mb1
                            />
                        )
                    })}
                    <Button
                        outline={true}
                        height={40}
                        width={320}
                        isLoading={isPaying}
                        text={`Pay with new card`}
                        onPress={throttle(async () => {
                            if (didPressPay.current) return;
                            didPressPay.current = true;
                            setIsPaying(true);
                            const { data } = payData;
                            const res = await Store.Api.Payment.stripeCreateDeposit({
                                amount: amount,
                                currency: currency,
                                paymentMethodId: 'new',
                            });
                            setIsPaying(false);
                            if (res.data.success && res.data.data?.client_secret) {
                                setPayData({
                                    show: true,
                                    options: {
                                        clientSecret: res.data.data?.client_secret,
                                    },
                                    data,
                                });
                                return;
                            }
                        })}
                    />
                    <Col height={1} mv1 backgroundColor={COLOR.MAIN} width={320} />
                    <Button
                        outline={true}
                        height={40}
                        width={320}
                        text={`Cancel Payment`}
                        onPress={() => {
                            modal.hide();
                        }}
                    />
                </Col>
            );
        }
        return (
            <Col flex1 middle>
                {!!stripePromise && (
                    <Elements stripe={stripePromise} options={payData.options}>
                        <PaymentForm
                            onPaid={e => {
                                console.log('onPaid', e);
                            }}
                            amount={payData.data?.displayAmount}
                            resellerId={resellerId}
                            queryString={`?amount=${payData.data?.amount}&f_amount=${fulfillAmount}&currency=${currency}`}
                        />
                    </Elements>
                )}
            </Col>
        );
    }

    return (
        <Col round1 bgWhite shadow p2 pv4 width={width * 0.7} maxHeight={height * 0.7}>
            <ScrollView>
                {payData.show ? renderPayment() : null}
            </ScrollView>
        </Col>
    );
};

export default DepositFundPopup;