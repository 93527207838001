import React, { useMemo, useState } from 'react';
import { Col, Row, RatioCol } from 'components';
import { Image } from 'react-native';
import { TProduct } from 'type';
import { COLOR } from 'const';

interface IProps {
  product?: TProduct
  physicalRatio: number,
  focusedArea: number,
}

const PrintAreas = ({ physicalRatio, product, focusedArea }: IProps) => {

  const [containerSize, setContainerSize] = useState({ width: 1, height: 1 });

  const onLayout = (e) => {
    const { width, height } = e.nativeEvent.layout;
    if (containerSize.width !== width || containerSize.height !== height) {
      setContainerSize({ width, height })
    }
  }

  const artboardWidth = useMemo(() => {
    let aWidth = 1;
    if (containerSize.width / containerSize.height < physicalRatio) {
      aWidth = containerSize.width * 0.9;
    } else {
      aWidth = containerSize.width * physicalRatio
    }
    return aWidth;
  }, [physicalRatio, containerSize]);

  return (
    <Col flex={2} backgroundColor={'#E6E6E6'} middle overflow={'hidden'} onLayout={onLayout}>
      <RatioCol
        width={artboardWidth} ratio={physicalRatio} bgWhite
      >
        {!!product?.bluePrintImage && (
          <Image
            source={{ uri: product?.bluePrintImage }}
            style={{
              width: '100%',
              height: '100%',
            }}
            resizeMode='stretch'
          />
        )}
        <Col absoluteFill>
        {(product?.printAreas || []).map((val, i) => {
            if (!product?.physicalHeight || !product.physicalWidth) return null;
            const top = !val.top ? 0 : (val.top * 100 / product.physicalHeight ) + '%';
            const left = !val.left ? 0 : (val.left * 100 / product.physicalWidth ) + '%';
            const width = !val.width ? 0 : (val.width * 100 / product.physicalWidth ) + '%';
            const height = !val.height ? 0 : (val.height * 100 / product.physicalHeight ) + '%';
            const isFocused = focusedArea === i;
            return (
              <Col
                key={'print-area-'+i}
                borderWidth={1}
                borderColor={isFocused ? COLOR.GREEN : COLOR.MAIN}
                backgroundColor={COLOR.GREY_LIGHT}
                absolute
                top={top}
                left={left}
                width={width}
                height={height}
              />
            );
          })}
        </Col>
      </RatioCol>
    </Col>
  )
};

export default PrintAreas;