
import Store from 'store';
import { COLOR, SCREEN } from 'const';
////////////////////////////////////////////////
import React, { useState, useRef, useEffect } from 'react';
import { BgIcon, useUIState } from 'components';
import {
  Text, ScrollView, View, TouchableOpacity, StyleSheet,
  useWindowDimensions, TextInput, Image,
} from 'react-native';
import { useNavFunc } from 'navigation';
import { ValHelper } from 'helpers';

////////////////////////////////////////////////


const Login = () => {
  const { navigate, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const UserStore = Store.useUserStore();
  const [{ loading }, setUI] = useUIState();

  const { width, height } = useWindowDimensions();
  const sizeScreen = 600;
  const minSizeText = 900;

  const oneUnit = width <= 600 ? (
    0.75
  ) : (
    width > minSizeText
    ? width / 1440
    : minSizeText / 1440
  );
  const oneUnitHeight = height > minSizeText
    ? height / 1024
    : minSizeText / 1024;
  const gap = 40 * oneUnitHeight;
  /* Style */

  const styles = StyleSheet.create({
    styleScrollViewContainer: {
      backgroundColor: '#E6E8F0',
    },
    styleLoginContainer: {
      flexDirection: 'row',
      width: '100vw',
      height: '100vh',
    },
    styleLoginContainer2: {
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
    },
    /////////////////////////////////////
    styleImageLoginContainer: {
      width: '50%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    styleImageLoginContainer2: {
      width: '100%',
      height: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    /////////////////////////////////////
    styleLoginFormContainer: {
      width: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    styleLoginFormContainer2: {
      width: '100%',
      alignItems: 'center',
      paddingBottom: 30 * oneUnit,
    },
    /////////////////////////////////////
    styleImageLoginView: {
      width: '94%',
      height: '94%',
      backgroundColor: 'white',
      borderRadius: 16 * oneUnit,
    },
    styleImageLogin: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    /////////////////////////////////////
    styleLoginFormView: {
      width: '80%',
    },
    /////////////////////////////////////
    styleHeaderContainer: {
    },
    styleHeaderText: {
      fontSize: 32 * oneUnit,
      fontSize: 32 * oneUnit,
      fontWeight: '520',
      color: '#101840',
      lineHeight: 40 * oneUnit,
      fontFamily: 'Inter, sans-serif',
    },
    styleHeaderDescription: {
      fontSize: 16 * oneUnit,
      fontWeight: '520',
      lineHeight: 24 * oneUnit,
      color: '#101840',
      fontFamily: 'Inter, sans-serif',
      marginTop: 16 * oneUnit,
    },
    /////////////////////////////////////  
    styleEmailContainer: {
      marginTop: gap,
    },
    /////////////////////////////////////
    stylePasswordContainer: {
      marginTop: gap,
    },
    styleConfirmPasswordContainer: {
      marginLeft: '5%',
      flex: 1,
    },
    styleInputLabelText: {
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
      fontWeight: '520',
      color: '#101840',
    },
    styleInputForm: {
      borderWidth: 1,
      borderColor: '#696F8C',
      borderRadius: 5,
      padding: 16 * oneUnit,
      marginTop: '2%',
      fontSize: 16 * oneUnit,
      fontWeight: '400',
      height: 52 * oneUnitHeight,
    },
    styleInputContainer: {
    },
    styleEye: {
      position: 'absolute',
      top: '55%',
      transform: [{ translateY: '-25%' }],
      right: 15 * oneUnit,
      opacity: 0.3,
      activeOpacity: 1,
    },
    styleEyePasswordInputed: {
      opacity: 1.0,
    },
    styleForgotPassword: {
      marginTop: 5 * oneUnit,
      fontSize: 14 * oneUnit,
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      textAlign: 'right',
      color: '#215DB0',
    },
    /////////////////////////////////////
    styleButtonContainer: {
      marginTop: 34 * oneUnitHeight,
    },
    styleTouchableOpacity: {
      backgroundColor: '#223363',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4 * oneUnit,
      height: 40 * oneUnitHeight,
      opacity: 0.7,
    },
    styleTouchableOpacityEnabled: {
      opacity: 1.0,
    },
    styleTouchableOpacityText: {
      fontSize: 14,
      fontWeight: '600',
      color: '#FFFFFF',
    },
    ////////////////////////////////////  
    styleOrContainer: {
      marginTop: 22 * oneUnitHeight,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    styleOrLineLeft: {
      backgroundColor: '#686677',
      height: 0.5,
      width: '45%',

    },
    styleOrText: {
      color: 'black',
      fontFamily: 'Inter, sans-serif',
      fontSize: 12 * oneUnit,
      fontWeight: '400',
    },
    styleOrLinerRight: {
      backgroundColor: '#686677',
      height: 0.5,
      width: '45%',
    },
    ////////////////////////////////////
    styleHaveanAcountContainer: {
      marginTop: 24 * oneUnitHeight,
    },
    styleHaveanAcountText: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 16 * oneUnit,
      fontWeight: '400',
      lineHeight: 24 * oneUnitHeight,
      color: '#101840',
    },
    styleHaveanAcountLink: {
      fontSize: 14 * oneUnit,
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      color: '#215DB0',
      marginLeft: 5,
    },
  });

  /* Logic */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isLoginEnabled =
    email.length > 0
    && password.length > 0
    ;
  const [shouldOpenEyePassword, setShouldOpenEyePassword] = useState(true);
  const [isOpenEye, setIsOpenEye] = useState(true);
  const [isCloseEye, setIsCloseEye] = useState(false);
  const openEyePassword = () => {
    setShouldOpenEyePassword((click) => !click);
    if (isOpenEye == true) {
      setIsOpenEye(false);
      setIsCloseEye(true);
    } else {
      setIsOpenEye(true);
      setIsCloseEye(false);
    }
  }

  /* Validate */
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const validateEmail = () => {
    const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailChecker.test(email)) {
      setErrorMessageEmail('Please provide a valid email.');
      return false;
    }
    setErrorMessageEmail('');
    return true;
  }

  /* Login */

  const [erroLoginMessage, setErroLoginMessage] = useState('');
  const handleLogin = async () => {
    setUI({ loading: true });
    try {
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });
      if (res.data.success) {
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        if (redirect) {
          navigate(redirect);
        } else {
          navigate(SCREEN.Home);
        }
      } else {
        setErroLoginMessage('Error: User does not exist');
      }
    } catch (err) {

    }
    setUI({ loading: false });

  }

  /* Show screen */

  return (
    <ScrollView style={styles.styleScrollViewContainer}>

      <View style={width > sizeScreen
        ? styles.styleLoginContainer
        : styles.styleLoginContainer2
      }>

        <View style={width > sizeScreen
          ? styles.styleImageLoginContainer
          : styles.styleImageLoginContainer2
        }>
          <View style={styles.styleImageLoginView}>
            <Image style={styles.styleImageLogin}
              source={require('../../assets/img/ImageLogin.png')} />
          </View>
        </View>

        <View style={
          width > sizeScreen
            ? styles.styleLoginFormContainer
            : styles.styleLoginFormContainer2
        }>
          <View style={styles.styleLoginFormView}>
            <View style={styles.styleHeaderContainer}>
              <Text style={styles.styleHeaderText}>{ValHelper.noSingleLastWordHTML('Welcome to Bottled Goose')}</Text>
              <Text style={styles.styleHeaderDescription}>
                Please input your email and password to login</Text>
            </View>

            <View style={styles.styleEmailContainer}>
              <Text style={styles.styleInputLabelText}>Email address</Text>
              <View style={styles.styleInputContainer}>
                <TextInput style={[styles.styleInputForm, { outline: 'none', }]}
                  placeholder={'Enter your email address'}
                  placeholderTextColor={'#8F95B2'}
                  onChangeText={(text) => setEmail(text)}
                  value={email}
                  nativeID='enter-email'
                ></TextInput>
              </View>
            </View>
            {errorMessageEmail && <Text
              style={{
                color: 'red',
                marginTop: 10 * oneUnit,
                fontSize: 14 * oneUnit,
              }}>
              {errorMessageEmail}</Text>}


            <View style={styles.stylePasswordContainer}>
              <Text style={styles.styleInputLabelText}>Password {isLoginEnabled}</Text>

              <View style={styles.styleInputContainer}>
                <TextInput style={[styles.styleInputForm, { outline: 'none', }]}
                  placeholder={'Enter your password'}
                  placeholderTextColor={'#8F95B2'}
                  onChangeText={(text) => setPassword(text)}
                  secureTextEntry={shouldOpenEyePassword}
                  value={password}
                  nativeID='enter-password'
                  onSubmitEditing={handleLogin}
                ></TextInput>
                <TouchableOpacity style={[styles.styleEye, password
                  && styles.styleEyePasswordInputed, { outline: 'none', }]} onPress={openEyePassword}
                >
                  <View style={{ display: isOpenEye ? '' : 'none' }}>
                    <BgIcon name="eye-open" size={16 * oneUnit} color="black" />
                  </View>

                  <View style={{ display: isCloseEye ? '' : 'none' }}>
                    <BgIcon name="eye-off" size={16 * oneUnit} color="black" />
                  </View>
                </TouchableOpacity>
              </View>
              <Text style={styles.styleForgotPassword}
                onPress={() => { window.location.href = 'https://bg-dev.bottledgoose.co.uk/forgot-password' }}
              >Forgot password?</Text>
            </View>
            <View style={styles.styleButtonContainer}>
              <TouchableOpacity
                style={[styles.styleTouchableOpacity,
                isLoginEnabled && styles.styleTouchableOpacityEnabled,
                { outline: 'none' },
                ]}
                onPress={() => {
                  // validateEmail();
                  handleLogin();
                }}
                nativeID='login-button'
                disabled={!isLoginEnabled}
              >
                <Text style={styles.styleTouchableOpacityText}>{loading ? 'Logging in..' : 'Log in'}</Text>
              </TouchableOpacity>
            </View>
            {erroLoginMessage && <Text
              style={{
                color: 'red',
                marginTop: 10 * oneUnit,
                fontSize: 14 * oneUnit,
              }}>
              {erroLoginMessage}</Text>}


            <View style={styles.styleOrContainer}>
              <View style={styles.styleOrLineLeft}></View>
              <Text style={styles.styleOrText}>Or</Text>
              <View style={styles.styleOrLinerRight}></View>
            </View>

            <View style={styles.styleHaveanAcountContainer}>
              <Text style={styles.styleHaveanAcountText}>
                Have an account?
                <Text style={styles.styleHaveanAcountLink}
                  onPress={() => { window.location.href = '/reseller-register' }}
                >
                  Reseller register
                </Text>
              </Text>
            </View>

          </View>
        </View>

      </View>
    </ScrollView>
  )
}

Login.routeInfo = {
  title: 'Login',
  path: '/login',
}

export default Login;