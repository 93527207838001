import React, { useEffect, useState, useRef } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, ShimmerLoading, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, modal, showPopupMessage } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, COLORS, SCREEN } from 'const';
import Store from 'store';
import { Image as ImageAnt } from 'antd';
import { Image, ScrollView } from 'react-native';
import { Entypo, AntDesign, EvilIcons, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import PrintAreas from './UpsertProduct.PrintAreas';
import { saveAs } from 'file-saver';
import { INCH_TO_MM } from 'const';
import ActivateModal from './UpsertProduct.Activate.Modal';
import OrderSampleModal from './OrderSampleModal';
import ProductDetail from 'components/products/ProductDetail';
import { ValHelper } from 'helpers';

const TAB = {
  LISTING_INFO: 'LISTING_INFO',
  PRINT_INFO: 'PRINT_INFO',
  PRICING: 'PRICING',
  items: [
    { title: 'Listing Info', key: 'LISTING_INFO' },
    { title: 'Print Info', key: 'PRINT_INFO' },
  ],
}

type TSelectResellerOption = {
  label: string,
  value: string,
  data?: any,
}

const LeftColumn = Col;
const MiddleColumn = Col;
const RightColumn = Col;

type TVariantOption = {
  variant: string,
  prices: Array<{
    amount: any,
    price: any,
  }>
}

const UpsertProduct: IScreen = () => {
  const modalRef = useRef<any>();
  const UserStore = Store.useUserStore();
  const ProductStore = Store.useProductStore();
  const { navigation, route } = useNavFunc();
  const [{ loading }, setCreatingUI] = useUIState();
  const [show360ProductEditor, setShow360ProductEditor] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const { id } = route.params || {};
  const { product, setProduct, uiState, hasDoneSomeEditing } = ProductStore.useProduct(id);
  const productRef = useRef(product);

  useEffect(() => {
    productRef.current = product;
  }, [product])

  const [curTab, setCurTab] = useState(TAB.LISTING_INFO);

  const [listResellers, setListResellers] = useState<Array<TSelectResellerOption>>([]);

  const onEditorMessage = async (data: { event: string, payload: any } | undefined) => {
    console.log('onEditorMessage', data);
    if (!!data && data.event === 'PRODUCT_LIBRARY_SAVE') {
      if (!!data.payload && data.payload.productId === id) {
        const res = await Store.Api.Product.detail({ id });
        if (res.data.success) setProduct({
          ...productRef.current,
          data: {
            ...productRef.current.data,
            preview: res.data.data.data?.preview,
          },
        });
      }
    }
  }
  // console.log(id)
  // useEffect(() => {
  //   if(!product) return;
  //   setTimeout(() => {

  //     const el = document.getElementById(id);
  //     // console.log('el', el)

  //     if (!el) return;
  //     // @ts-ignore
  //     el.onCtaBtnClick = (e: any) => {
  //         if (!product) return;
  //         navigation.reset({
  //           index: 0,
  //           routes: [{ name: SCREEN.ResellerCreateMyOwnProduct, params: { designId: "new", productId: product.id } }],
  //         });

  //       e.preventDefault();
  //     }
  //   }, 300);


  // }, [id, product]);
  // useEffect(() => {
  //   if(uiState.fetching) return;
  //   setTimeout(() => {

  //     const el = document.getElementById(id);
  //     // console.log('el', el)

  //     if (!el) return;
  //     // @ts-ignore
  //     el.onBtnNormalClick = (e: any) => {
  //         window.open(product?.artboardUrl || "https://www.bottledgoose.co.uk/pages/artboards", "_blank");

  //       e.preventDefault();
  //     }
  //   }, 300);


  // }, [id, uiState.fetching]);

  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListResellers([
          { label: 'All resellers', value: 'all' },
          ...list.map(v => ({
            label: `${v.firstName} ${v.lastName}`,
            value: v.id,
          }))
        ]);
      }
    })();
  }, []);

  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const orderASample = async () => {
    modalRef.current?.show();
    // if (!UserStore.user?.addressLine1) {
    //   alert('Please update your address first');
    //   window.open('/settings?tabId=billing', '_blank');
    //   return;
    // }
    // const res = await Store.Api.Product.orderASample({
    //   productId: id,
    //   resellerId: UserStore.getResellerId(UserStore.user),
    //   productName: product?.name,
    // });
    // if (res.data.success) {
    //   alert('Your request has been sent. We will send you a sample soon');
    // }
  }

  const onPressCreateMyOwn = async () => {
    if (!product) return;
    if (isLoading) return;
    setIsLoading(true);
    // navigation.reset({
    //   index: 0,
    //   routes: [{ name: SCREEN.ResellerCreateMyOwnProduct, params: { designId: "new", productId: product.id } }],
    // });
    try {
      const res = await Store.Api.Design.upsert({
        name: product.name,
        isCustomizable: true,
        productId: product.id,
        width: product.physicalWidth,
        height: product.physicalHeight,
        printAreas: product.printAreas,
        customProduct: !!product.customProduct,
        printOnDemand: !!product.printOnDemand,
        wholeSale: !!product.wholeSale,
        image: product.image,
        description: product.description,
        resalePrice: 0,
        data: {},
        updateShopify: false,
      });
      navigation.reset({
        index: 0,
        routes: [{
          name: SCREEN.ResellerCreateMyOwnProduct,
          params: { designId: res.data.data.id, productId: product.id }
        }],
      });
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  const renderReady = () => {
    if (breakpoint === 'xs') return (
      <Col flex1 middle>
        <Text>Please use bigger screen to see this page.</Text>
      </Col>
    )
    return (
      <ProductDetail
        product={product}
        onCtaBtnClick={onPressCreateMyOwn}
        // ctaBtnLoading={isLoading}
        onBtnNormalClick={() => {
          window.open(product?.artboardUrl || "https://www.bottledgoose.co.uk/pages/artboards", "_blank");
        }}
      />
    )
    // return <div dangerouslySetInnerHTML={{
    //   __html: `
    //       <product-detail id="${id}">
    //         ${JSON.stringify(product)}
    //       </product-detail>
    //   `
    // }}></div>
  };

  const preview3dAvailable = (product?.previewData || []).filter(v => v.previewType === '3d-model').length > 0;

  const renderCommonListingInfo = () => {
    const find3dGroup = (product?.previewData || []).find(v => v.previewType === '3d-model');
    const data = (find3dGroup?.previewItems || [])[0]?.data;
    const demoImage = 'https://print-manager-media.s3.eu-west-1.amazonaws.com/personify-full-transparent.png';
    return (
      <>
        <MiddleColumn flex={2} backgroundColor={'#E6E6E6'}>
          {show360ProductEditor ? (
            <Col flex1>
              <iframe
                src={window.location.href.includes('iframe_dev=1') ? `http://localhost:3009${data}&image=${demoImage}` : `${ValHelper.editorHost}${data}&image=${demoImage}`}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  backgroundColor: COLOR.MAIN,
                }}
                allowFullScreen
              />
            </Col>
          ) : (
            <Col flex1 middle>
              <RatioCol
                width='80%'
                ratio={1}
                borderColor={COLOR.GREY}
                borderStyle='dashed'
                borderWidth={!product?.image ? 1 : 0}
              >
                <Image source={{ uri: product?.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
              </RatioCol>

              <Row
                mt2 minWidth='90%' minHeight={70}
                borderColor={COLOR.GREY} borderStyle='dashed'
                borderWidth={!product?.galleries?.length ? 1 : 0}
                flexWrap='wrap' middle
              >
                <ImageAnt.PreviewGroup>
                  {product?.galleries && product?.galleries.map((val, i) => (
                    <Col width={80} height={80} m0 key={'gallery-' + i}>
                      <ImageAnt
                        src={val}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </Col>
                  ))}
                </ImageAnt.PreviewGroup>
              </Row>
            </Col>
          )}
        </MiddleColumn>
      </>
    )
  }

  const renderTabListing = () => {
    const shouldIncludeVAT = UserStore.user?.country === "United Kingdom";
    return (
      <>
        <LeftColumn middle flex={2} p2 overflow={'scroll'}>
          <Text h2 center mb4>{product?.name}</Text>

          <Text center h3 mb1>Description</Text>
          <Text center mb2>{product?.description}</Text>

          <Text center h3 mb1>Packaging</Text>

          {!!product?.packagingImage &&
            <Image
              source={{ uri: product?.packagingImage }}
              style={{ width: 200, height: 200, marginBottom: 8 }}
              resizeMode='contain'
            />
          }
          {!!product?.packagingDescription &&
            <Text center mb2>{product?.packagingDescription}</Text>
          }

          {/* <Text center h3 mb1>Our price: <Text fontSize={24}>£{shouldIncludeVAT ? (Number(product?.price) * 1.2).toFixed(2) : product?.price}</Text> <Text caption ml1>{shouldIncludeVAT ? "VAT included" : ""}</Text></Text> */}
          <Text center h3 mb1>Our price: <Text fontSize={24}>£{product?.price}</Text> <Text caption ml1>exclude VAT</Text></Text>
          <Text center mb2>This is how much you will be charged each time you sell this product*</Text>

          <Text center subtitle1 color="#FEA629">We recommend putting your design on the artboard and uploading via the customiser</Text>

          <Row alignSelf={'center'}>
            <Button
              mv2
              outline
              bgHovered={COLOR.GREY_BG}
              text='Download artboard'
              borderRadius={20}
              width={180}
              iconLeft={
                <Entypo name="download" size={18} color={COLOR.MAIN} />
              }
              onPress={() => {
                window.open(product?.artboardUrl || "https://www.bottledgoose.co.uk/pages/artboards", "_blank");
              }}
            />
            <Button
              solid
              text='Create my own'
              ml1
              borderRadius={20}
              isLoading={loading}
              onPress={async () => {
                // navigation.navigate(SCREEN.ResellerCreateMyOwnProduct, { id })
                // if (!preview3dAvailable) {
                //   const shouldContinue = confirm('This product does not have preview, are you sure to continue?');
                //   if (!shouldContinue) return;
                // }
                navigation.reset({
                  index: 0,
                  routes: [{ name: SCREEN.ResellerCreateMyOwnProduct, params: { designId: "new", productId: product.id } }],
                });
              }}
            />
            {/* <Button
              solid
              text='Order a sample'
              borderRadius={20}
              onPress={orderASample}
            /> */}
          </Row>
        </LeftColumn>
        {renderCommonListingInfo()}
      </>
    )
  }

  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[
        { title: `Product Catalogue`, screen: SCREEN.ListProducts },
        { title: product?.name || `Product details` },
      ]}
    >
      <Col borderWidth={1} borderColor={COLORS.BORDER} bgWhite
        flex1
        overflow={'hidden'}
        paddingBottom={40}
        onLayout={(e) => {
          const height = e.nativeEvent.layout.height;
          setContainerHeight(height);
        }}
      >
        {uiState.errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{uiState.errorMes}</Text>
          </Col>
        ) : (
          uiState.fetching ? (
            <Row height={50} stretch>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          ) : (
            renderReady()
          )
        )}
      </Col>
    </CMSLayout>
  );
};

UpsertProduct.routeInfo = {
  title: 'Product Library - Bottled Goose',
  path: '/product-library/:id',
};

export default UpsertProduct;
