import React, { useState, useRef, useEffect } from 'react';
import { BgIcon, showPopupMessage, useUIState } from 'components';
import { IScreen } from 'type';
import { Text, ScrollView, View, TouchableOpacity, StyleSheet,
  useWindowDimensions, TextInput, Image, 
} from 'react-native';

import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';

const ResellerRegister: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const UserStore = Store.useUserStore();
  const [{ loading }, setUI] = useUIState();


  const {width, height} = useWindowDimensions();
  const sizeScreen = 600;
  const sizeScreenHeight = 600;
  const minSizeText = 800;

  const oneUnit = width > minSizeText 
    ? width / 1440
    : minSizeText / 1440;
  const oneUnitHeight = height > minSizeText 
    ? height / 1024
    : minSizeText / 1024;
  const gap = 40 * oneUnitHeight; 
   /* Style */

  const styles = StyleSheet.create({    
    styleScrollViewContainer: {
      backgroundColor: '#E6E8F0',
    },
    styleLoginContainer: {
      flexDirection: 'row',
      width: '100vw',
      height: '100vh',
    },
    styleLoginContainer2: {
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
    },
    /////////////////////////////////////
    styleImageLoginContainer: {
      width: '50%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    styleImageLoginContainer2: {
      width: '100%',
      height: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    /////////////////////////////////////
    styleLoginFormContainer: {
      width: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    styleLoginFormContainer2: {
      width: '100%',
      alignItems: 'center',
      paddingBottom: 30 * oneUnit,
    },
    /////////////////////////////////////
    styleImageLoginView: {
      width: '94%',
      height: '94%',
      backgroundColor: 'white',
      borderRadius: 16  * oneUnit,
    },
    styleImageLogin: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    /////////////////////////////////////
    styleLoginFormView: {
      width: '80%',
    },
    /////////////////////////////////////
    styleHeaderContainer: {
    },
    styleHeaderText: {
      fontSize: 32 * oneUnit, 
      fontWeight: '520',
      color: '#101840',
      lineHeight: 40 * oneUnit,
      fontFamily: 'Inter, sans-serif',
    },
    styleHeaderDescription: {
      fontSize: 16 * oneUnit, 
      fontWeight: '520',
      lineHeight: 24 * oneUnit,
      color: '#101840', 
      fontFamily: 'Inter, sans-serif',
      marginTop: 16 * oneUnit,
    },
    /////////////////////////////////////    
    styleFullnameContainer: {
      marginTop: gap,
    },
    styleEmailContainer: {
      marginTop: gap,
    },
    /////////////////////////////////////
    styleRegisterPasswordContainer: {
      flexDirection: 'row',
      marginTop: gap,
    },
    stylePasswordContainer: {
      width: '48.5%',
    },
    styleConfirmPasswordContainer: {
      marginLeft: '3%',
      flex: 1,
    },
    styleInputLabelText: {
      fontSize: 14,      
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      color: '#101840',
    },
    styleInputForm: {
      borderWidth: 1,
      borderColor: '#696F8C',
      borderRadius: 5,
      padding: 16 * oneUnit,
      marginTop: '2%',
      fontSize: 16 * oneUnit,
      fontWeight: '400',
      height: 52 * oneUnitHeight,
    },
    styleInputContainer: { 
    },
    styleEye: {
      position: 'absolute',
      top: '50%',
      transform: [{translateY: '-25%'}],
      right: 15 * oneUnit,
      opacity: 0.3,
      activeOpacity: 1,
    },
    styleEyePasswordInputed: {
      opacity: 1.0,
    },
    styleForgotPassword: {
      marginTop: 5 * oneUnit,
      fontSize: 14 * oneUnit,
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      textAlign: 'right',
      color: '#3366FF',
    },
    /////////////////////////////////////
    styleAgreetoTermsContainer: {
      marginTop: gap,
      flexDirection: 'row',
    },
    checkboxContainer: {
      width: 16 * oneUnit,
      height: 16 * oneUnit,
      borderWidth: oneUnit,
      borderColor: 'gray',
      marginTop: 7 * oneUnit,
      justifyContent: 'center',
      alignItems: 'center', 
    },
    checked: {
      backgroundColor: '#223363',
    },
    checkMark: {
      fontSize: 12 * oneUnit,
      color: 'white',
    },
    styleAgreetoTermsText: {
      marginLeft: 12 * oneUnit ,
      fontSize: 12,
      fontWeight: '400',
      color: '#223363',
      lineHeight: 28 * oneUnit,
      flex: 1,
    },
    styleAgreetoTermsLink: {
      fontSize: 12,
      fontWeight: '400',
      color: '#215DB0',
      marginLeft: 5 * oneUnit,
    },
    /////////////////////////////////////
    styleButtonContainer: {
      marginTop: 34 * oneUnitHeight,      
    },
    styleTouchableOpacity: {
      backgroundColor: '#223363',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4 * oneUnit,
      opacity: 0.7,      
      height: 40 * oneUnitHeight,
    },
    styleTouchableOpacityEnabled: {
      opacity: 1.0,      
    },
    styleTouchableOpacityText: {
      fontSize: 14,
      fontWeight: '600',
      color: '#FFFFFF',    
    },
    ////////////////////////////////////  
    styleOrContainer: {
      marginTop: 22 * oneUnitHeight,    
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    styleOrLineLeft: {
      backgroundColor: '#686677',
      height: 0.5,
      width: '45%',  
  
    },
    styleOrText: {
      color: '#black',
      fontFamily: 'Inter, sans-serif',
      fontSize: 12 * oneUnit,
      fontWeight: '400',
    },
    styleOrLinerRight: {
      backgroundColor: '#686677',
      height: 0.5,
      width: '45%',
    },
    ////////////////////////////////////
    styleHaveanAcountContainer: {
      marginTop: 24 * oneUnitHeight,
    },
    styleHaveanAcountText: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 16 * oneUnit,
      fontWeight: '400',
      lineHeight: 24 * oneUnitHeight,
      color: '#101840',
    },
    styleHaveanAcountLink: {
      fontSize: 14 * oneUnit,
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      color: '#215DB0',
      marginLeft: 5,
    },
  });

  /* Logic */
  const [isChecked, setIsChecked] = useState(false);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const isLoginEnabled = 
    fullname.length > 0
    && email.length > 0 
    && password.length > 0
    && confirmPassword.length > 0
    && isChecked
  ;
  const [shouldOpenEyePassword, setShouldOpenEyePassword] = useState(true);
  const [shouldOpenEyeConfirmPassword, setShouldOpenEyeConfirmPassword] = useState(true);
  const [isOpenEye, setIsOpenEye] = useState(true);
  const [isCloseEye, setIsCloseEye] = useState(false);

  const openEyePassword = () => {
    setShouldOpenEyePassword((click) => !click);
    if (isOpenEye == true) { 
      setIsOpenEye(false);
      setIsCloseEye(true);
    } else {
      setIsOpenEye(true);
      setIsCloseEye(false);
    }
  }
  
  const [isOpenEyeConfirmPassword, setIsOpenEyeConfirmPassword] = useState(true);
  const [isCloseEyeConfirmPassword, setIsCloseEyeConfirmPassword] = useState(false);
  const openEyeConfirmPassword = () => {
    setShouldOpenEyeConfirmPassword((click) => !click);
    if (isOpenEyeConfirmPassword == true) { 
      setIsOpenEyeConfirmPassword(false);
      setIsCloseEyeConfirmPassword(true);
    } else {
      setIsOpenEyeConfirmPassword(true);
      setIsCloseEyeConfirmPassword(false);
    }
  }

  /* Validate */
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const validateEmail = () => {  
    const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
    if (!emailChecker.test(email)) {
      setErrorMessageEmail('Please provide a valid email.');
      return false;
    }
    setErrorMessageEmail('');
    return true;
  }

  const [errorMessageConfirmPassword, setErroMessageConfirmPassword] = useState('');
  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setErroMessageConfirmPassword('Not the same as your password.');
      return false;
    }
    setErroMessageConfirmPassword('');
    return true;
  }

  const handleRegister = async () => {
    if (!validateEmail() || !validateConfirmPassword()) {
      return;
    }
    setUI({ loading: true });
    const names = fullname.split(' ');
    const firstName = names[0];
    const lastName = names.slice(1).join(' ');
    try {
      const res = await Store.Api.User.register({
        firstName,
        lastName,
        email,
        password,
        role: 'reseller',
        otherData: {
          registeredUserAgent: navigator.userAgent,
        },
      });
      if (res.data.error) {
        setUI({ loading: false });
        // return alert(String(res.data.error));
        return showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'
  
        });
      }
      showPopupMessage({
        title: '',
        content: 'Register successfully.',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'

      });
      // alert('Register successfully.');
      const token = res.data.data?.token;
      Store.Client.setToken(token);
      UserStore.set({
        token,
        user: res.data.data?.publicInfo,
      });
      if (redirect && redirect !== 'undefined') {
        reset(redirect);
      } else {
        reset(SCREEN.Home);
      }
      // reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect  });
      // alert('Register successfully. Please login now');
      // reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect  });
    } catch(err) {
      setUI({ loading: false, errorMes: '' });
      // alert(String(err));
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
  }

  /* Show screen */

  return (
    <ScrollView style={styles.styleScrollViewContainer}>   
      <View style={width > sizeScreen 
        ? styles.styleLoginContainer
        : styles.styleLoginContainer2
      }>    

        <View style={ width > sizeScreen 
          ? styles.styleImageLoginContainer
          : styles.styleImageLoginContainer2
        }>
          <View style={styles.styleImageLoginView}>
            <Image style={styles.styleImageLogin} 
            source={require('../../assets/img/imageRegister.png')} />
          </View>   
        </View>

        <View style={
          width > sizeScreen 
          ? styles.styleLoginFormContainer
          : styles.styleLoginFormContainer2
        }>
          <View style={styles.styleLoginFormView}>
            <View style={styles.styleHeaderContainer}>
              <Text style={styles.styleHeaderText}>Welcome to Bottled Goose</Text>
              <Text style={styles.styleHeaderDescription}>
              Kindly fill in your details below to create an account</Text>
            </View>

            <View style={styles.styleFullnameContainer}>
              <Text style={styles.styleInputLabelText}>Full name</Text>
              <View style={styles.styleInputContainer}>
                <TextInput style={[styles.styleInputForm, {outline: 'none',}]}
                  placeholder={'Enter your full name'}
                  placeholderTextColor={'#8F95B2'}
                  nativeID='enter-full-name'
                  onChangeText={(text) => setFullname(text)}
                  value={fullname}
                  ></TextInput>
              </View>
            </View>

            <View style={styles.styleEmailContainer}>
              <Text style={styles.styleInputLabelText}>Email</Text>
              <View style={styles.styleInputContainer}>
                <TextInput style={[styles.styleInputForm, {outline: 'none',}]}
                  placeholder={'Enter your email'}
                  placeholderTextColor={'#8F95B2'}
                  nativeID='enter-email'
                  onChangeText={(text) => setEmail(text)}
                  value={email}
                  ></TextInput>
              </View>
            </View>
            {errorMessageEmail && <Text 
              style={{
                color: 'red', 
                marginTop: 10 * oneUnit,
                fontSize: 14 * oneUnit,
              }}>
            {errorMessageEmail}</Text>}

            <View style={styles.styleRegisterPasswordContainer}>
              <View style={styles.stylePasswordContainer}>
                <Text style={styles.styleInputLabelText}>Password {isLoginEnabled}</Text>
                
                <View style={styles.styleInputContainer}>
                  <TextInput style={[styles.styleInputForm,{outline: 'none',}]}
                    placeholder={'Enter your password'}
                    placeholderTextColor={'#8F95B2'}
                    onChangeText={(text) => setPassword(text)}
                    secureTextEntry={shouldOpenEyePassword}
                    value={password}
                    onChangeText={(text) =>  setPassword(text)}
                    nativeID='enter-password'
                  ></TextInput>
                  <TouchableOpacity style={[styles.styleEye, password 
                    && styles.styleEyePasswordInputed, {outline: 'none',}]} 
                    onPress={openEyePassword}
                  >
                    <View style={{display: isOpenEye ? '' : 'none'}}> 
                      <BgIcon name="eye-open" size={16 * oneUnit} color="black"/>
                    </View>
                  
                    <View style={{display: isCloseEye ? '' : 'none'}}> 
                      <BgIcon name="eye-off" size={16 * oneUnit} color="black" />
                    </View>
                  </TouchableOpacity>             
                </View>

              </View>
              <View style={styles.styleConfirmPasswordContainer}>
                <Text style={styles.styleInputLabelText}>Confirm Password {isLoginEnabled}</Text>
                
                <View style={styles.styleInputContainer}>
                  <TextInput style={[styles.styleInputForm,{outline: 'none',}]}
                    placeholder={'Enter your password'}
                    placeholderTextColor={'#8F95B2'}
                    onChangeText={(text) => setConfirmPassword(text)}
                    secureTextEntry={shouldOpenEyeConfirmPassword}
                    value={confirmPassword}
                    nativeID='confirm-password'
                  ></TextInput>
                  <TouchableOpacity style={[styles.styleEye, confirmPassword
                    && styles.styleEyePasswordInputed, {outline: 'none',}]} onPress={openEyeConfirmPassword}
                  >
                    <View style={{display: isOpenEyeConfirmPassword ? '' : 'none'}}> 
                      <BgIcon name="eye-open" size={16 * oneUnit} color="black"/>
                    </View>
                  
                    <View style={{display: isCloseEyeConfirmPassword ? '' : 'none'}}> 
                      <BgIcon name="eye-off" size={16 * oneUnit} color="black" />
                    </View>
                  </TouchableOpacity>             
                </View>
              {errorMessageConfirmPassword && <Text 
                style={{
                  color: 'red', 
                  marginTop: 10 * oneUnit,
                  fontSize: 14 * oneUnit,
                }}>
              {errorMessageConfirmPassword}</Text>}
              </View>
            </View>            
            
            <View style={styles.styleAgreetoTermsContainer}>
              <TouchableOpacity
                style={[styles.checkboxContainer, isChecked && styles.checked, {outline: 'none',}]}
                onPress={() => 
                  setIsChecked(!isChecked)
                }
              >
                {isChecked && <Text style={styles.checkMark}>✓</Text>}
              </TouchableOpacity>

              <Text style={styles.styleAgreetoTermsText}>
                By ticking, you are confirming that you have read, understood and agree to
                <Text style={styles.styleAgreetoTermsLink}
                  onPress={() => {window.location.href='https://www.bottledgoose.co.uk/pages/policies'}}
                >
                  our terms and conditions
                </Text>
              </Text>
            </View>


            <View style={styles.styleButtonContainer}>
              <TouchableOpacity 
                style={[styles.styleTouchableOpacity, 
                  isLoginEnabled && styles.styleTouchableOpacityEnabled
                ]}              
                onPress={handleRegister}
                disabled={!isLoginEnabled || !isChecked}                
              >
                <Text style={styles.styleTouchableOpacityText}>{loading? 'Registering..' : 'Register Account'}</Text>
              </TouchableOpacity>
            </View>

            <View style={styles.styleOrContainer}>
              <View style={styles.styleOrLineLeft}></View>
              <Text style={styles.styleOrText}>Or</Text>
              <View style={styles.styleOrLinerRight}></View>
            </View>

            <View style={styles.styleHaveanAcountContainer}>
              <Text style={styles.styleHaveanAcountText}>
              Already have an account?
                <Text style={styles.styleHaveanAcountLink}
                  onPress={() => {window.location.href='/login'}}
                >
                  Log in
                </Text>
              </Text>                  
            </View>
          </View>
        </View>        
      </View>

    </ScrollView>    
  )
} 
ResellerRegister.routeInfo = {
  title: 'Reseller register - Bottled Goose - Print Manager',
  path: '/reseller-register',
};

export default ResellerRegister;
