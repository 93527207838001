import React, { useEffect, useState } from 'react';




interface IProps {
  product?: any;
  onCtaBtnClick?: Function;
  onBtnNormalClick?: Function;
  ctaBtnLoading?: boolean;
}

const ProductDetail = ({ onCtaBtnClick, onBtnNormalClick, product, ctaBtnLoading }: IProps) => {
  const [id] = useState(Math.random().toString(36).substring(7));

  useEffect(() => {
    if(!product) return;
    const el = document.getElementById(id);
    if (!el) return;
    // @ts-ignore
    el.onCtaBtnClick = (e: any) => {
      e.preventDefault();
      onCtaBtnClick();
    }
    // @ts-ignore

    el.onBtnNormalClick = (e: any) => {
      e.preventDefault();
      onBtnNormalClick();
    }
  }, [id, product]);
  // @ts-ignore
  return <div style={{flex: 1}} dangerouslySetInnerHTML={{
      __html: `
          <product-detail id="${id}" ctaBtnLoading="${ctaBtnLoading}">
            ${JSON.stringify(product)}
          </product-detail>
      `
    }}></div>
}

export default ProductDetail;
