import React, { useEffect, useState } from 'react';

interface IProps {
  products?: any;
  onProductClick?: Function;
}

const ListProductContainer = ({ onProductClick, products }: IProps) => {
  const [id] = useState(Math.random().toString(36).substring(7));
  useEffect(() => {
    if(products.length === 0) return;
      const el = document.getElementById(id);
    if (!el) return;
    // @ts-ignore
    el.onProductClick = (e:any) => {
      onProductClick(e)
    }
  }, [id, products]);

  // @ts-ignore
  return <div id={'container-' + id}
    style={{ marginTop: 20 }}
    dangerouslySetInnerHTML={{
      __html: `
        <product-list-container id="${id}">
          ${JSON.stringify(products)}
        </product-list-container>
      `
    }} 
  />
}

export default ListProductContainer;