import React, { useState } from "react";
import { Col, Row, Text } from "components/base";
import { IColProps } from "components/base/Col";
import { COLOR } from "const";
import { get, set } from "lodash";
import Button from "../button/Button";
import { Input02 } from "../form";
import TouchField from "../button/TouchField";
import { EvilIcons } from "@expo/vector-icons";

export interface ITableFormProps extends IColProps {
  columns: { label: string, key: string, isMultiline?: boolean }[],
  onChange?: (data: any[]) => void,
  addBtnTitle?: string,
  data?: any[],
}

const TableForm = (props: ITableFormProps) => {
  const [data, setData] = useState<any[]>(props.data || [{}]);
  const { columns, onChange, addBtnTitle, ...restProps } = props;

  const onAddRow = () => {
    setData(s => [...s, {}]);
  }

  const onRemove = (i) => {
    const arr = [...data];
    arr.splice(i, 1);
    setData(arr);
    onChange?.(arr);
  }

  const onChangeRow = (value, key, idx) => {
    const arr = [...data];
    set(arr, `[${idx}].${key}`, value);
    setData(arr);
    onChange?.(arr);
  }

  return (
    <Col {...restProps}>
      <Row marginTop={16}>
        <Row flex1>
          {columns.map(col => (
            <Col flex1 key={`h${col.label}`}>
              <Text>{col.label}</Text>
            </Col>
          ))}
        </Row>
        <Col width={50}>
        </Col>
      </Row>
      {data.map((i, idx) => (
        <Row marginTop={8} key={`row${idx}`}>
          <Row flex1 alignItems="flex-start">
            {columns.map((col, cIdx) => (
              <Col flex1 paddingRight={8} key={`c${cIdx}${idx}`}>
                <Input02
                  height={col.isMultiline ? 200 : 50}
                  multiline={col.isMultiline}
                  value={get(i, col.key)}
                  onChangeText={(v) => onChangeRow(v, col.key, idx)}
                  inputProps={{
                    // @ts-ignore
                    style: { outline: 'none' }
                  }}
                />
              </Col>
            ))}
          </Row>
          <TouchField width={50} height={50} middle onPress={() => onRemove(idx)} backgroundColor={COLOR.RED}>
            <EvilIcons name="trash" size={24} color={COLOR.WHITE} />
          </TouchField>
        </Row>
      ))}
      <Button
        mt2
        text={addBtnTitle || "Add row"}
        outline
        bgHovered={COLOR.GREY_BG}
        onPress={onAddRow}
      />
    </Col>
  );
};

export default TableForm;
