import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';


export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
}

export const ASSETS = {
  SHOPIFY: require('./img/shopify.png'),
  LOGO_MOBILE: require('./img/Logo.png'),
  MESS_MOBILE: require('./img/welcome-image.svg'),
  WOOCOMMERCE: require('./img/woocommerce.png'),
  LOGO: require('./img/whitebottledgoosenew@4x.png'),
  CUSTOMER_ADDRESS_ICON: require('./img/address.png'),
  SHOPIFY_TEXT: require('./img/shopify-text-logo.png'),
  GRAB_ADMIN_URL: require('./img/grab_admin_url.png'),
  ADD_PERSONALISED_BUTTON: require('./img/add_personalised_button.png'),
  CUSTOMIZE_ADD_BLOCKS_1: require('./img/customize_add_blocks_1.png'),
  CUSTOMIZE_ADD_BLOCKS_2: require('./img/customize_add_blocks_2.png'),
  ETSY_LOGO: require('./img/etsy.svg'),
}
