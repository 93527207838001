import React, { useEffect, useState } from 'react';
import Store from 'store';
import { RatioCol } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';




interface IProps {
  product?: any;
  bannerData?: any;
  productWithBanner?: any;
  onBannerClick?: Function;
}

const ImageBanner = ({ bannerData, onBannerClick, productWithBanner }: IProps) => {
  const [bannerId] = useState(Math.random().toString(36).substring(7));

  useEffect(() => {
    if (!bannerData) return;
    const el = document.getElementById(bannerId);
    if (!el) return;
    // @ts-ignore
    el.onBannerClick = (e: any) => {
      onBannerClick(e);
    }

    // @ts-ignore
    el.onCloseClick = () => {
      el.parentElement.parentElement.parentElement.remove();
    }
  }, [bannerId, bannerData]);


  // @ts-ignore
  return <RatioCol marginTop={20} ratio={1156 / 232} width={"100%"}>
    <div style={{

      width: "100%",
      height: "100%",

    }}
      dangerouslySetInnerHTML={{
        __html: `
          ${productWithBanner.length === 0 ? '' : `
            <image-banner id="${bannerId}">
              ${JSON.stringify(bannerData)}
            </image-banner>
          `}
        `
      }}>

    </div>
  </RatioCol>
}

export default ImageBanner;