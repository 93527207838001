import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IScreen, TDesign, TOnlineStore } from 'type';
import { CMSLayout, Col, Row, Text, Button, ShimmerLoading, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, modal, Input, InputField, ButtonOutline, showPopupMessage } from 'components';
import { useNavigationMiddleWare } from 'navigation/NavigationContext';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, COLORS, SCREEN } from 'const';
import Store from 'store';
import { Image, useWindowDimensions } from 'react-native';
import { Image as ImageAnt, Button as AntButton } from 'antd';
import { Entypo, AntDesign, EvilIcons, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import PrintAreas from './UpsertProduct.PrintAreas';
import { saveAs } from 'file-saver';
import { INCH_TO_MM } from 'const';
import { ValHelper } from 'helpers';

const VariantDesignModal = ({ variant, designId, onChange }) => {
  const { width, height } = useWindowDimensions();

  const User = Store.useUserStore();

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    console.log("onEditorMessage", data);
    if (!!data && (data.event === "DESIGN_SAVE" || data.event === "CLOSE_EDITOR")) {
      console.log("DESIGN_SAVE")
      // close modal
      modal.hide();
    } else if (!!data && data.event === "VARIANT_SAVE") {
      const newVariant = data.payload.variant;
      console.log('newVariant design data', newVariant);
      onChange({
        variantDesignId: newVariant.id,
        style: variant?.style || '',
        price: variant?.price || 0,
        image: newVariant.image,
        galleries: newVariant.galleries,
      })
      modal.hide();
    }
  };

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        <iframe
          src={
            window.location.href.includes("iframe_dev=1")
              ? `http://localhost:3009/design/${designId}/variant/${variant.variantDesignId}?admin=1&userId=${User.user?.id}`
              : `${ValHelper.editorHost}/design/${designId}/variant/${variant.variantDesignId}?admin=1&dev=${ValHelper.isDev ? 1 : 0}&userId=${User.user?.id}`
          }
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: COLOR.MAIN,
          }}
          onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
            onEditorMessage
          )}
        />
      </Col>
      {/* <Col middle p1>
        <Grid xs='33%' md='1:1:any' mt1 middle width={Math.max(300, width * 0.6)}>
          <Col m0>
            <Text mb0 caption>Style</Text>
            <Input02
              value={variantStyle}
              onChange={setVariantStyle}
            />
          </Col>
          <Col m0>
            <Text mb0 caption>Price</Text>
            <Input02
              value={price}
              onChange={setPrice}
            />
          </Col>
          <Col m0>
            <Row marginTop={15}>
              <Button
                text='Save'
                width={90}
                height={30}
                mr1
                opacity={readyToSave ? 1 : 0.5}
                onPress={() => {
                  if (!readyToSave) return;
                  if (!variantStyle || !price) {
                    return alert('Please fill in style and price fields');
                  }
                  const iframeElement = Store.Client.editor.element;
                  // send event
                  iframeElement?.contentWindow?.postMessage(
                    {
                      event: "TRIGGER_SAVE_VARIANT",
                      payload: {
                        style: variantStyle,
                        price: price,
                      },
                    },
                    "*"
                  );
                }}
              />
              <Button
                text='Cancel'
                outline bgHovered={COLOR.GREY_BG}
                width={90}
                height={30}
                onPress={() => {
                  modal.hide();
                }}
              />
            </Row>
          </Col>
        </Grid>
      </Col> */}
    </Col>
  );
}

const Variants = ({ design, setDesign, onRequestSampleOrder, product, onFocusVariant }) => {
  const { updateEditting } = useNavigationMiddleWare();
  const [show, setShow] = useState(true);
  const alreadyHasVariants = (design?.variants || []).length > 0;

  const screenName = "ResellerCreateMyOwnProduct";

  const onNewVariant = () => {
    modal.show(
      <VariantDesignModal
        variant={{
          variantDesignId: 'new',
          style: '',
          price: 0,
          image: '',
          galleries: [],
        }}
        designId={design.id}
        onChange={(newVariant) => {
          const newVariants = [...design.variants];
          newVariants.push(newVariant);
          setDesign({
            ...design,
            variants: newVariants
          })
        }}
      />
    );
    updateEditting(screenName, true);
  }

  const onPressAddVariant = () => {
    if ((design?.galleries || []).length === 0) {
      showPopupMessage({
        title: '',
        content: 'Please click Edit design first',
        buttonOkText: 'OK',
        typeHighlight: 'warning',
        contentHighlight: 'No design detected',
      });
    } else if (alreadyHasVariants) {
      onNewVariant();
    } else {
      setDesign({
        ...design,
        variants: [
          {
            style: "Default",
            image: design.image,
            galleries: design.galleries,
            variantDesignId: design.id,
            price: design.resalePrice || 0,
          }
        ]
      })
    }
  }

  return (
    <Col flex1>
      <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={16} />
      <Row justifyContent="space-between">
        <Text marginBottom={16} fontSize={16} fontWeight="600">Variants</Text>
        <AntButton
          type="text"
          disabled={!design?.id}
          onClick={() => setShow(s => !s)}
        >
          <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
            {show ? "Hide" : "Show"}
          </Text>
        </AntButton>
      </Row>
      {show && (
        <>
          <Text>Use this function to add multiple designs to the same SKU</Text>
          <Text mb2>If you require assistance with this, click here</Text>
          {design?.variants?.map((variant, index) => {
            return (
              <Col key={'variant-' + index} mb2 onPress={() => onFocusVariant(variant)} p1>
                <Row alignItems={'flex-start'}>
                  <Col width={150} mr1>
                    <ImageAnt.PreviewGroup>
                      <Col width={150} height={150}>
                        <ImageAnt
                          src={variant.image}
                          style={{ width: 150, height: 150, objectFit: 'cover' }}
                        />
                        {/* <Image source={{ uri: variant.image }} style={{ width: 150, height: 150 }} resizeMode='cover' /> */}
                      </Col>
                    </ImageAnt.PreviewGroup>
                  </Col>
                  <Col flex1>
                    <InputField
                      title="Style"
                      value={variant.style}
                      onChangeText={(v) => {
                        const newVariants = [...design.variants];
                        newVariants[index].style = v;
                        setDesign({
                          ...design,
                          variants: newVariants
                        })
                        updateEditting(screenName, true);
                      }}
                      marginBottom={16}
                      inputProps={{
                        onFocus: () => onFocusVariant(variant),
                      }}
                    />
                    <InputField
                      title="Price"
                      value={variant.price}
                      onChangeText={(v) => {
                        const newVariants = [...design.variants];
                        newVariants[index].price = v;
                        setDesign({
                          ...design,
                          variants: newVariants
                        })
                        updateEditting(screenName, true);
                      }}
                      marginBottom={16}
                      inputProps={{
                        onFocus: () => onFocusVariant(variant),
                      }}
                    />
                    <Row flexWrap={'wrap'} justifyContent="space-between">
                      <Row>
                        <AntButton
                          type="primary"
                          onClick={() => {
                            modal.show(
                              <VariantDesignModal
                                variant={variant}
                                designId={design.id}
                                onChange={(newVariant) => {
                                  const newVariants = [...design.variants];
                                  newVariants[index] = newVariant;
                                  const obj: any = { variants: newVariants };
                                  if (index === 0) {
                                    obj.image = newVariant.image;
                                    obj.galleries = newVariant.galleries;
                                  } else {
                                    onFocusVariant(newVariant);
                                  }
                                  setDesign(s => ({
                                    ...s,
                                    ...obj
                                  }))
                                }}
                              />
                            )
                          }}
                          style={{ marginRight: 16, backgroundColor: COLORS.HEADLINES }}
                        >
                          <Text fontSize={16} fontWeight="600" colorWhite>Edit design</Text>
                        </AntButton>
                        <AntButton
                          type="primary"
                          onClick={() => {
                            onRequestSampleOrder(variant);
                          }}
                          style={{ marginRight: 16, backgroundColor: COLORS.HEADLINES }}
                        >
                          <Text fontSize={16} fontWeight="600" colorWhite>Order a sample</Text>
                        </AntButton>
                      </Row>
                      {/* {index !== 0 && ( */}
                      <AntButton
                        type="primary"
                        onClick={() => {
                          const newVariants = [...design.variants];
                          newVariants.splice(index, 1);
                          setDesign({
                            ...design,
                            variants: newVariants
                          })
                          updateEditting(screenName, true);
                        }}
                        style={{ backgroundColor: COLORS.RED }}
                      >
                        <Text fontSize={16} fontWeight="600" colorWhite>Delete</Text>
                      </AntButton>
                      {/* )} */}
                    </Row>

                  </Col>
                </Row>
              </Col>
            );
          })}
          <Row flexWrap={'wrap'}>
            <ButtonOutline
              icon="add"
              title="Add variant"
              onPress={onPressAddVariant}
            />
          </Row>
        </>
      )}
    </Col>
  )
};

export default Variants;
