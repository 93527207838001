import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IScreen, TDesign, TOnlineStore } from 'type';
import { CMSLayout, Col, Row, Text, Button, ShimmerLoading, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, modal, Input, InputField, ButtonOutline, showPopupMessage } from 'components';
import { useNavigationMiddleWare } from 'navigation/NavigationContext';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, COLORS, SCREEN } from 'const';
import Store from 'store';
import { Image, useWindowDimensions } from 'react-native';
import { Image as ImageAnt, Button as AntButton } from 'antd';
import { Entypo, AntDesign, EvilIcons, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import PrintAreas from './UpsertProduct.PrintAreas';
import { saveAs } from 'file-saver';
import { INCH_TO_MM } from 'const';
import { ValHelper } from 'helpers';

const EditDesignModal = ({ designId, onDesignSave }: any) => {
  const { width, height } = useWindowDimensions();

  const User = Store.useUserStore();

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    console.log("onEditorMessage", data);
    if (!!data && data.event === "CLOSE_EDITOR") {
      // close modal
      modal.hide();
    } else if (!!data && data.event === "DESIGN_SAVE") {
      // close modal
      modal.hide();
      // update design
      !!onDesignSave && onDesignSave(data.payload.designId);
    }
  };

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        <iframe
          src={
            window.location.href.includes("iframe_dev=1")
              ? `http://localhost:3009/design/${designId}?admin=1&userId=${User.user?.id}${ValHelper.isDev ? "&dev=1" : ""}`
              : `${ValHelper.editorHost}/design/${designId}?admin=1&userId=${User.user?.id}${ValHelper.isDev ? "&dev=1" : ""}`
          }
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: COLOR.MAIN,
          }}
          onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
            onEditorMessage
          )}
        />
      </Col>
    </Col>
  );
}

export default EditDesignModal;