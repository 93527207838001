import React, { useEffect, useState, useRef } from "react";
import { IScreen, TOnlineStore } from "type";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  TouchField,
  Input02,
  Select01,
  RatioCol,
  RatioCol2,
  UploadFile,
  useUIState,
  useRefState,
  Grid,
  Input,
  Switch,
  showPopupMessage,
  Tooltip,
} from "components";
import { useNavFunc } from "navigation";
import { useDynamicResponsiveValue } from "quickly-react";
import { COLOR, SCREEN } from "const";
import { Store } from "store";
import { Image } from "react-native";
import { Entypo, AntDesign } from "@expo/vector-icons";
import { UpsertResellerTabs } from "./UpsertReseller.Tabs";
import { UpsertResellerDesign } from "./UpsertReseller.Designs";
import { UpsertResellerTransactions } from "./UpsertReseller.Transactions";

const UpsertReseller: IScreen = () => {
  const { navigation, route, reset } = useNavFunc();
  // @ts-ignore
  const { id, tab } = route.params || {};

  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const { user, setUser, uiState } = UserStore.useUser(id, false);
  console.log("UpsertReseller", id, user);

  const [headerKey, setHeaderKey] = useState("");
  const [headerValue, setHeaderValue] = useState("");

  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: removing }, setRemoveUI] = useUIState();

  const [shops, getShops, setShops] = useRefState<Array<TOnlineStore>>([]);

  const getListShop = async (p) => {
    try {
      const resellerId = id;
      const { list, hasNext, total, error } = await ShopStore.getList(
        p,
        resellerId
      );
      console.log({ list, hasNext, total, error });
      if (error) return;
      setShops(list);
    } catch (err) { }
  };

  const onChangeField = (label) => (newValue) => {
    setUser({
      ...user,
      [label]: newValue,
    });
  };

  useEffect(() => {
    if (!tab)
      navigation.navigate(SCREEN.UpsertReseller, { id: id, tab: "info" });
    if (tab === "info" && id !== "new") {
      getListShop(1);
    }
  }, [tab, id]);

  const submit = async () => {
    setSubmitUI({ loading: true });
    try {
      console.log(user);
      const res = await Store.Api.User.upsertReseller({
        id: id === "new" ? undefined : id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        password: user?.password,
        addressLine1: user?.addressLine1 || "",
        addressLine2: user?.addressLine2 || "",
        town: user?.town || "",
        country: user?.country || "",
        postCode: user?.postCode || "",
        isAutoAccept: user?.isAutoAccept,
        accountName: user?.accountName,
        otherData: !!headerKey
          ? {
            ...user?.otherData,
            bgServices: {
              ...user?.otherData?.bgServices,
              authenHeaders: {
                [headerKey]: headerValue,
              },
            },
          }
          : user?.otherData,
      });
      if (res.data.error) {
        showPopupMessage({
          title: "",
          content: String(res.data.error),
          buttonOkText: "OK",
          // 
          typeHighlight: "danger",
          contentHighlight: "Error",
        });
        // alert(res.data.error);
      } else if (res.data.data) {
        showPopupMessage({
          title: "",
          content: "Reseller information successfully submitted.",
          buttonOkText: "OK",
          // 
          typeHighlight: "success",
          contentHighlight: "Success",
        });
        // alert('Reseller information successfully submitted');
        // navigation.reset(SCREEN.UpsertReseller, { id: res.data.data.id });
        navigation.reset({
          index: 0,
          routes: [
            { name: SCREEN.UpsertReseller, params: { id: res.data.data.id } },
          ],
        });
      }
    } catch (err) {
      showPopupMessage({
        title: "",
        content: String(err),
        buttonOkText: "OK",
        // 
        typeHighlight: "danger",
        contentHighlight: "Error",
      });
      // alert(String(err));
    }
    setSubmitUI({ loading: false });
  };

  useEffect(() => {
    if (!user) return;
    if (!user.otherData?.bgServices?.authenHeaders) return;
    const keys = Object.keys(user.otherData?.bgServices?.authenHeaders);
    setHeaderKey(keys[0]);
    setHeaderValue(user.otherData?.bgServices?.authenHeaders[keys[0]]);
  }, [user]);

  const renderPrintAndShipService = () => {
    return (
      <>
        <Text subtitle1 mb1>
          BG Print & Ship service API:
        </Text>
        <Col p2 round1 mb2 backgroundColor={COLOR.GREY_LIGHT}>
          <Text>
            https://bg-production.personify.tech/api/bg-services/{user?.id}
            /new-order
          </Text>
        </Col>
        <Text subtitle1 mb1>
          Webhook for receive events:
        </Text>
        <Input
          placeholder="Webhook URL"
          value={user?.otherData?.bgServices?.webhookUrl || ""}
          mb2
          onChange={(v) => {
            setUser({
              ...user,
              otherData: {
                ...user?.otherData,
                bgServices: {
                  ...user?.otherData?.bgServices,
                  webhookUrl: v,
                },
              },
            });
          }}
        />
        <Text subtitle1 mb1>
          Webhook authentication headers:
        </Text>
        {(() => {
          return (
            <Grid xs="100%" md="50%" marginHorizontal={-10}>
              <Col p0 m0>
                <Input
                  placeholder="Header key"
                  value={headerKey}
                  mb2
                  onChange={setHeaderKey}
                />
              </Col>
              <Col p0 m0>
                <Input
                  placeholder="Header value"
                  value={headerValue}
                  mb2
                  onChange={setHeaderValue}
                />
              </Col>
            </Grid>
          );
        })()}
      </>
    );
  };

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Row>
          <Text h3>
            {id === "new"
              ? "Create new reseller"
              : `${user?.firstName} ${user?.lastName}`}
          </Text>
          <UpsertResellerTabs
            activeTab={tab}
            ml1
            onChangeTab={(newKey) => {
              navigation.navigate(SCREEN.UpsertReseller, { id, tab: newKey });
            }}
          />
        </Row>
        <Row>
          <Button
            text={"Delete"}
            width={100}
            height={40}
            borderRadius={20}
            mr1
            backgroundColor={"red"}
            onPress={async () => {
              // const shouldContinue = confirm('Are you sure to delete this reseller?');
              // if (!shouldContinue) return;
              showPopupMessage({
                title: "",
                content: "Are you sure to delete this reseller?",
                buttonOkText: "OK",
                onButtonOkClick: async () => {
                  const res = await Store.Api.User.deleteReseller({ id });
                  if (res.data.error) {
                    showPopupMessage({
                      title: "",
                      content: String(res.data.error),
                      buttonOkText: "OK",
                      // 
                      typeHighlight: "danger",
                      contentHighlight: "Error",
                    });
                    // alert(res.data.error);
                  } else {
                    reset(SCREEN.ListResellers);
                  }
                },
                typeHighlight: "danger",
                contentHighlight: "This action is not reversible.",
              });
            }}
          />
          <Button
            isLoading={submitting}
            text={id === "new" ? "Create" : "Save"}
            width={100}
            height={40}
            borderRadius={20}
            onPress={submit}
          />
        </Row>
      </Row>
      {tab === "transactions" && <UpsertResellerTransactions resellerId={id} />}
      {tab === "designs" && <UpsertResellerDesign resellerId={id} />}
      {tab === "info" && (
        <Col flex1 m2 mv1 p1 round1 bgWhite overflow={"scroll"}>
          <Grid xs="100%" md="50%" alignItems={"flex-start"} mb2>
            <Col m1>
              <Text subtitle1 mb2>
                Reseller information
              </Text>
              <Text mb0 caption>
                Email
              </Text>
              <Input
                placeholder="Email"
                value={user?.email}
                mb1
                onChange={onChangeField("email")}
              />
              <Text mb0 caption>
                Password
              </Text>
              <Input
                placeholder="Password"
                value={user?.password}
                mb1
                password
                onChange={onChangeField("password")}
              />
              <Text mb0 caption>
                First Name
              </Text>
              <Input
                placeholder="First Name"
                value={user?.firstName}
                mb1
                onChange={onChangeField("firstName")}
              />
              <Text mb0 caption>
                Last Name
              </Text>
              <Input
                placeholder="Last Name"
                value={user?.lastName}
                mb1
                onChange={onChangeField("lastName")}
              />
              <Text mb0 caption>
                Account Name
              </Text>
              <Input
                placeholder="Account Name"
                value={user?.accountName}
                mb1
                onChange={onChangeField("accountName")}
              />
              <Text mb0 caption>
                Address Line 1
              </Text>
              <Input
                placeholder="Address Line 1"
                value={user?.addressLine1}
                mb1
                onChange={onChangeField("addressLine1")}
              />
              <Text mb0 caption>
                Address Line 2
              </Text>
              <Input
                placeholder="Address Line 2"
                value={user?.addressLine2}
                mb1
                onChange={onChangeField("addressLine2")}
              />
              <Text mb0 caption>
                Town
              </Text>
              <Input
                placeholder="Town"
                value={user?.town}
                mb1
                onChange={onChangeField("town")}
              />
              <Text mb0 caption>
                Country
              </Text>
              <Input
                placeholder="Country"
                value={user?.country}
                mb1
                onChange={onChangeField("country")}
              />
              <Text mb0 caption>
                Post Code
              </Text>
              <Input
                placeholder="Post Code"
                value={user?.postCode}
                mb1
                onChange={onChangeField("postCode")}
              />
            </Col>
            <Col m1>
              {!!user && (
                <>
                  {/* {renderPrintAndShipService()} */}
                  {/* <Text subtitle1>Auto accept order</Text>
                  <Switch size={80} height={60} isOn={user?.isAutoAccept} toggleSwitch={() => {
                    setUser({
                      ...user,
                      isAutoAccept: !user?.isAutoAccept,
                    })
                  }} /> */}
                  <Text subtitle1 mb2>
                    Store link
                  </Text>
                  <Row round0 borderThin flexWrap={"wrap"} minHeight={45} mb1>
                    {shops.map((s) => {
                      return (
                        <Text m0 key={s.url}>
                          <a
                            href={s.url}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              color: COLOR.FONT,
                              padding: 4,
                              margin: 4,
                              backgroundColor: "rgba(0,0,0,0.05)",
                            }}
                          >
                            {s.url}
                          </a>
                        </Text>
                      );
                    })}
                  </Row>
                  <Text subtitle1 mb1>
                    Sample order token
                  </Text>
                  <Input
                    placeholder="Sample order token"
                    value={
                      typeof user?.otherData?.sampleToken === "number"
                        ? user?.otherData?.sampleToken
                        : 10
                    }
                    mb1
                    onChange={(v) => {
                      setUser({
                        ...user,
                        otherData: {
                          ...user.otherData,
                          sampleToken: isNaN(v) ? v : Number(v),
                        },
                      });
                    }}
                  />
                  {
                    user?.otherData?.registedDevice && (
                      (() => {
                        const deviceData = user?.otherData?.registedDevice;
                        return (
                          <Col mb1>
                            <Text subtitle1 mb1>
                              Registered Device
                            </Text>
                            <Tooltip title={`User Agent: ${user?.otherData?.registeredUserAgent}`} position="top">
                              <Text>
                                {deviceData?.device?.brand} {deviceData?.device?.model} ({deviceData?.client?.name} {deviceData?.client?.version}) ({deviceData?.device?.type})
                              </Text>
                            </Tooltip>
                          </Col>
                        )
                      })()
                    )
                  }
                </>
              )}

              {/* <Text subtitle1 mb2>Live Orders</Text>

            <Col borderThin round1 height={200}>
            
            </Col> */}
            </Col>
          </Grid>
          {/* <Col p1>
          <Text subtitle1 mb2>Order History</Text>
          <Col borderThin round1 height={200}>
            
            </Col>
        </Col> */}
        </Col>
      )}
    </CMSLayout>
  );
};

UpsertReseller.routeInfo = {
  title: "Resellers - Bottled Goose",
  path: "/resellers/:id/:tab",
};

export default UpsertReseller;
